/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br",
    ul: "ul",
    li: "li",
    strong: "strong",
    h2: "h2",
    code: "code",
    ol: "ol",
    a: "a"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "After you integrate with the Apple Pay JS API and the Payroc API, you need to set up Apple Pay for each individual merchant. We then generate a unique ID for the merchant's domain that you need to start an Apple Pay session.", React.createElement(_components.br), "\n", "To set up Apple Pay for a merchant, complete the following steps:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Step 1."), " Add the domain verification file to the merchant's domain."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Step 2."), " Add the merchant's domain to the Self-Care Portal."), "\n"), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.p, null, "Add the following subfolder to the merchant's domain:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "/.well-known/apple-developer-merchantid-domain-association")), "\n", React.createElement(_components.h2, null, "Step 1. Add the domain verification file to the merchant's domain"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Log into the ", React.createElement(_components.a, {
    href: "https://payments.payroc.com/"
  }, "Self-Care Portal"), "."), "\n", React.createElement(_components.li, null, "From the side menu, select ", React.createElement(_components.strong, null, "Settings"), ", and then select ", React.createElement(_components.strong, null, "Apple Pay Domains"), "."), "\n", React.createElement(_components.li, null, "Select ", React.createElement(_components.strong, null, "DOWNLOAD DOMAIN VERIFICATION FILE"), "."), "\n", React.createElement(_components.li, null, "Add the file to the merchant's domain in the following subfolder:", React.createElement(_components.br), "\n", React.createElement(_components.code, null, "/.well-known/apple-developer-merchantid-domain-association")), "\n"), "\n", React.createElement(_components.h2, null, "Step 2. Add the merchant's domain to the Self-Care Portal"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Select ", React.createElement(_components.strong, null, "ADD NEW DOMAIN"), "."), "\n", React.createElement(_components.li, null, "In the ", React.createElement(_components.strong, null, "Domain"), " field, enter the merchant's domain name, for example, website.com."), "\n", React.createElement(_components.li, null, "Select ", React.createElement(_components.strong, null, "Save"), "."), "\n"), "\n", React.createElement(_components.p, null, "The Self-Care Portal redirects you to the Apple Pay Domains page where it displays the unique ID of the merchant's domain."), "\n", React.createElement(Admonition, {
    type: "important"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Important:"), " Store the unique ID of the merchant's domain. You need to send the unique ID when you start an Apple Pay Session.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
